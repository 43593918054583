import React from "react"
import SEO from "../components/Seo"
import Title from "../components/Title/index"

const NotFoundPage = () => (
  <div className="relative h-full">
    <SEO title="404: No encontrado" />
    <Title>¡Oh no!</Title>
    <h2 className="text-2xl text-gray-700">Esta página no existe</h2>
    <p
      className="text-primary-500 font-black absolute absolute-center"
      style={{ fontSize: "10rem" }}
    >
      404
    </p>
  </div>
)

export default NotFoundPage
